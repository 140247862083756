import { YoutubeEmbed } from "../../components";
import { useNavigate } from "react-router-dom";
import image_bernard from "../../assets/image_bernard.png";
import image_austin from "../../assets/image_austin.png";
import image_mary from "../../assets/image_mary.png";

const ComplementHome = ({ sendToContact = () => {} }) => {
  const navigate = useNavigate();
  const goTo = (route = "/") => {
    navigate(route);
  };
  return (
    <div className="bg-black  w-full relative flex flex-col items-center pb-16">
      <div className="container p-4 h-[600px] pt-24">
        <YoutubeEmbed embedId="16snA8e3PYs" />
      </div>
      <div className="container pt-24 text-white flex flex-col-reverse lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1 flex flex-col gap-3 p-4">
          <p className=" text-2xl">Manage Your Crew</p>
          <span className="font-thin text-sm">
            Simplify crew management. VP Studio enables you to list all
            available crew members, assigning them to specific tasks and
            departments. Coordinate schedules, assignments, and availability to
            keep your production running smoothly.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r w-1/2 from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center  whitespace-nowrap"
          >
            Checkout Studio Genie
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-crew.png"
            alt=""
          />
        </div>
      </div>
      <div className="container text-white flex flex-col lg:flex-row gap-2 items-center lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/unreal-genie-script.png"
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col gap-3 p-4">
          <p className=" text-2xl">Take your script to the 3D World</p>
          <span className="font-thin text-sm">
            Quickly assemble scenes using your in-engine and at-hand Shot List
            as a guide. To make the most out of your pipeline, we’ll handle all
            of the tedious work.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r w-1/2 from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center  whitespace-nowrap"
          >
            Checkout Unreal Genie
          </a>
        </div>
      </div>
      <div className="container pt-24 text-white flex flex-col-reverse lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1 flex flex-col gap-3 p-4 justify-center">
          <p className=" text-2xl">Take your Scouting to the Next Level</p>
          <span className="font-thin text-sm">
            Simplify your location scouting process.VP Studio enables you to
            compare potential locations with those mentioned in your script
            breakdown. Upload location photos and details, and match them
            against script requirements to find the perfect fit for your scenes.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r w-1/2 from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center  whitespace-nowrap"
          >
            Checkout Studio Genie
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-locations.png"
            alt=""
          />
        </div>
      </div>

      <div className="container pt-24 text-white flex flex-col gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <span className="text-4xl font-bol py-8"> The Team</span>
        <div className="flex flex-col lg:flex-row gap-5 items-center">
          <img src={image_mary} alt="" />
          <div className="flex flex-1 flex-col gap-2">
            <p className="text-2xl">Mary</p>
            <ul className="list-disc px-4 font-thin text-sm">
              <li>
                <span className="font-bold">Background:</span> Creative
                director, graphic design, brand expansion, marketing strategy,
                advertising, MBA, design agency founder
              </li>
              <li>
                <span className="font-bold">Key Skills:</span> Bridging the gap
                between tech and brands through strategy, aesthetic design, and
                marketing initiatives
              </li>
              <li>
                <span className="font-bold">Notable Achievements:</span> Former
                Creative Director for The Sims at Electronic Arts. Collaborated
                with Spotify, Tinder, Amazon, Snapchat, Tik Tok.
              </li>
              <li>
                <span className="font-bold">Unique Value:</span> Bridging
                artistic and commercial worlds with cutting-edge technology for
                large audiences
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 items-center">
          <img src={image_austin} alt="" />
          <div className="flex flex-1 flex-col gap-2">
            <p className="text-2xl">Austin</p>
            <ul className="list-disc px-4 font-thin text-sm">
              <li>
                <span className="font-bold">Background:</span> Director of
                photography, majority owner of Trance Productions
              </li>
              <li>
                <span className="font-bold">Key Skills:</span> Innovative
                storytelling, visual mastery, industry networking
              </li>
              <li>
                <span className="font-bold">Notable Achievements:</span> Filmed
                content for Nike, New Balance, KFC, Chicago Bulls;
                Collaborations with NBC, HBO, Disney, Kanye West, Chance the
                Rapper
              </li>
              <li>
                <span className="font-bold">Unique Value:</span> Extensive
                network and industry experience in production for TV, movies,
                and music videos
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 items-center">
          <img src={image_bernard} alt="" />
          <div className="flex flex-1 flex-col gap-2">
            <p className="text-2xl">Bernard Mendez</p>
            <ul className="list-disc px-4 font-thin text-sm">
              <li>
                <span className="font-bold">Background:</span> Former Captain
                with US Army Special Operations; former PM at Amazon
              </li>
              <li>
                <span className="font-bold">Key Skills:</span> Leadership,
                technical expertise, customer-centric product development
              </li>
              <li>
                <span className="font-bold">Notable Achievements:</span>{" "}
                Developed Amazon's HIRE 2.0 recruiting software, prior
                experience as a founder, led high-level military teams in global
                engagements
              </li>
              <li>
                <span className="font-bold">Unique Value:</span> Combines
                tactical leadership with a technical background for
                customer-focused product development
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="container pb-24 pt-14 text-white flex flex-col gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <p className="text-2xl text-center py-2">Unreal + Genie </p>
        <div className="grid lg:grid-cols-5 gap-4">
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Generate</span>
            <span className="text-xs">
              Generate quick high quality AI images to help you visualize and
              paint your vision.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Inpainting</span>
            <span className="text-xs">
              Edit images and textures to your liking painting over them and
              tell us what to change from it.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Skybox</span>
            <span className="text-xs">
              Envision quick and easy lighting and sky setups with our AI
              generate skyboxes.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Upscale</span>
            <span className="text-xs">
              Is your image or texture ready to be used, make it larger without
              loosing any of the good looks.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Segment</span>
            <span className="text-xs">
              Easily create previs by combining the power of Image Gen with
              Segmentation, creating quick silhouettes, characters and props.
            </span>
          </div>
        </div>
        <div className="flex items-center w-full justify-center">
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default ComplementHome;
