import React, { useState } from "react";
import { InputText, InputArea } from "../../components";
import useContact from "../../hook/useContact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const contactApi = useContact();
  const [contactForm, setContactForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });

  const [inputError, setInputError] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });
  const onChangeValue = (value, name) => {
    setContactForm({ ...contactForm, [name]: value });
  };

  const verifyData = (d) => {
    const ie = {};
    setInputError(ie);

    if (!d.firstname) {
      ie.firstname = "First Name is required";
      setInputError(ie);
      return {
        status: false,
        message: "First Name is required",
      };
    }

    if (!d.lastname) {
      ie.lastname = "Last Name is required";
      setInputError(ie);
      return {
        status: false,
        message: "Last Name is required",
      };
    }

    if (!d.email) {
      ie.email = "Email is required";
      setInputError(ie);
      return {
        status: false,
        message: "Email is required",
      };
    }

    if (
      !d.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      ie.email = "Email does not have mail format";
      setInputError(ie);
      return {
        status: false,
        message: "Email does not have mail format",
      };
    }

    if (!d.message) {
      ie.message = "Message is required";
      setInputError(ie);
      return {
        status: false,
        message: "Message is required",
      };
    }

    return {
      status: true,
      message: "",
    };
  };

  const sendData = async () => {
    const d = { ...contactForm };
    const resValidate = verifyData(d);
    if (resValidate.status) {
      const res = await contactApi.sendContact(d);
      if (res.status) {
        setContactForm({
          name: "",
          email: "",
          message: "",
          contact_type: "landing",
        });
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } else {
      toast.error(resValidate.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
  return (
    <div className="bg-black text-white py-8 pb-8 relative flex flex-col items-center  gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
      <div className="container">
        <div className="flex flex-col gap-4 mt-4 items-center">
          <p className="uppercase text-4xl font-bold">GET IN TOUCH</p>
          <p className=" ">REACH OUT FOR MORE INFORMATION</p>
          <div className="p-2 flex flex-col gap-2 w-full">
            <div className="flex flex-row gap-2">
              <InputText
                placeHolder="Write your first name here"
                name="firstname"
                label="First Name"
                value={contactForm.firstname}
                onChangeValue={onChangeValue}
                classValue="flex-1"
                error={inputError.firstname}
              />
              <InputText
                placeHolder="Write your last name here"
                name="lastname"
                label="Last Name"
                value={contactForm.lastname}
                onChangeValue={onChangeValue}
                classValue="flex-1"
                error={inputError.lastname}
              />
            </div>
            <InputText
              placeHolder="Write your email here"
              type="email"
              name="email"
              label="Email"
              value={contactForm.email}
              onChangeValue={onChangeValue}
              classValue="flex-1"
              error={inputError.email}
            />
            <InputArea
              placeHolder="Message"
              name="message"
              label="Tell Us About Your Projects"
              value={contactForm.message}
              onChangeValue={onChangeValue}
              classValue="flex-1"
              error={inputError.message}
            />
            <div className="flex flex-col items-center shrink-0  flex-1">
              <button
                type="button"
                onClick={sendData}
                className="w-full py-4 border border-[#02b24f] rounded-lg"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
