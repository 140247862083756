import Interco_logo from "../assets/interco.png";
const Footer = () => {
  return (
    <nav className=" bg-[#2D2D2D] flex flex-col items-center z-50 relative">
      <div className="container flex flex-row justify-center py-4 lg:px-0 px-8">
        <a href="https://www.interco.ai/" target="_blank">
          <img src={Interco_logo} className="h-8" alt="VPGenie" />
        </a>
      </div>
    </nav>
  );
};
export default Footer;
